<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 large-4 large-offset-4">
        <h1 class="medium-heading">
          Forgot or Change Password
        </h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 large-4 large-offset-4">
        <p>
          Please enter your email address. An email will be sent to you with instructions on
          how to change your password.
        </p>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 large-4 large-offset-4">
        <label>Email address</label>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 large-4 large-offset-4">
        <input
          v-model="user.email_address"
          type="email" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8 large-4 large-offset-4">
        <button
          v-on:click="requestNewPassword"
        class="button large expanded">Send</button>
      </div>
    </div>
    <div
      v-if="message.length > 0"
      class="message-wrap">
        <span v-on:click="message = ''" class="close-message">X</span>
        <h3 class="message-text">{{ message }}</h3>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'RequestPassword',
  data() {
    return {
      user: {
        email_address: '',
      },
      message: '',
    };
  },
  methods: {
    requestNewPassword() {
      axios.post('/users/requestPasswordReset.json', this.user)
        .then((response) => {
          if (response.data.response === 1) {
            this.message = 'Sorry, we couldn\'t find that email address';
          } else if (response.data.response === 2) {
            this.message = 'Thank you. We have sent you a password reset link';
            setTimeout(() => {
              this.message = '';
            }, 6000);
          }
        });
    },
  },
};
</script>
